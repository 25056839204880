<template>
  <section class="container mt-3 has-fullheight">
    <separator class="separator is-primary"></separator>
    <h2 class="title is-2 has-text-centered">
      {{ $t("friends.friends") }}
    </h2>
    <div class="columns is-vcentered is-centered mb-2">
      <div class="column is-half">
        <search-input v-model="searchQuery" :placeholder="$t('hints.search_hint_invitations')" :button-visible="false"></search-input>
      </div>
      <div class="column is-half">
        <dropdown-select :items="dropdownListItems" v-model="selectedList">
          <template #dropdown="{ item }">
            <span>{{ item }}</span>
          </template>
        </dropdown-select>
      </div>
    </div>

    <div class="is-relative" v-if="searchQuery">
      <div class="columns friend-list is-multiline is-vcentered" v-if="searchResult && searchResult.length">
        <div class="column is-half" v-for="user in searchResult" :key="user.user_identity_token">
          <attendee-list-item
            :user="user"
            :is-current-user="false"
            :inbox-enabled="inboxEnabled"
            :show-friends-button="true"
          ></attendee-list-item>
        </div>
      </div>
      <div v-else-if="searchResult && !isLoading" class="placeholder-container">
        <div class="has-text-centered has-text-secondary">
          {{ $t("common.not_found") }}
        </div>
      </div>
      <list-loading-indicator :active="isLoading" class="mt-6" v-else></list-loading-indicator>
    </div>
    <template v-else>
      <div v-if="selectedList === 0">
        <friend-list class="friend-list"></friend-list>
      </div>
      <div v-else-if="selectedList === 1">
        <div v-if="getRequestedInvitations.length" class="columns friend-list">
          <div class="column is-one-third" v-for="invitation in getRequestedInvitations" :key="invitation.user_identity_token">
            <friend-invitation :invitation="invitation"></friend-invitation>
          </div>
        </div>
        <div v-else class="placeholder-container">
          <div class="has-text-centered has-text-secondary">
            {{ $t("friends.empty_pending") }}
          </div>
        </div>
      </div>
      <div v-else-if="selectedList === 2">
        <div v-if="getPendingInvitations.length" class="columns friend-list">
          <div class="column is-one-third" v-for="invitation in getPendingInvitations" :key="invitation.user_identity_token">
            <friend-invitation :invitation="invitation"></friend-invitation>
          </div>
        </div>
        <div v-else class="placeholder-container">
          <div class="has-text-centered has-text-secondary">
            {{ $t("friends.empty_requested") }}
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Constants from "@/web/constants";
import SearchInput from "@/shared/components/SearchInput";
import FriendInvitation from "@/web/components/friends/FriendInvitation";
import FriendList from "@/web/components/friends/FriendList";
import Separator from "@/web/components/shared/Separator";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import DropdownSelect from "@/shared/components/DropdownSelect";
import AttendeeListItem from "@/web/components/attendees/AttendeeListItem";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "Friends",

  components: {
    ListLoadingIndicator,
    FriendList,
    FriendInvitation,
    SearchInput,
    Separator,
    DropdownSelect,
    AttendeeListItem,
  },

  mixins: [AuthorizedRouteMixin],

  data() {
    return {
      selectedList: 0,
      searchQuery: "",
    };
  },

  mounted() {
    this.loadInvitations({ showNotification: false });
    this.loadFriendsPage();
    if (this.$route.name === Constants.ROUTE_FRIENDS_INVITATIONS) {
      this.selectedList = 1;
    }
    this.setEventAttendeesSource();
  },

  computed: {
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapGetters("friends", ["getPendingInvitations", "getRequestedInvitations"]),
    ...mapState("attendees", ["searchResult"]),
    ...mapGetters("attendees", ["isLoading"]),
    ...mapGetters(["inboxEnabled"]),

    dropdownListItems() {
      return [this.$t("friends.my_friends"), this.$t("friends.invitations_requested"), this.$t("friends.invitations_pending")];
    },
  },

  methods: {
    ...mapActions("friends", ["loadInvitations"]),
    ...mapActions("inboxUsers", ["loadFriendsPage"]),
    ...mapMutations("attendees", ["setEventAttendeesSource", "clearSearchResult"]),
    ...mapActions("attendees", ["searchAttendees"]),
  },

  watch: {
    searchQuery: function (newVal) {
      if (newVal.length) {
        this.searchAttendees(newVal);
      } else {
        this.clearSearchResult();
      }
    },
  },
};
</script>

<style scoped>
.friend-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 420px);
  margin-top: 20px;
}

.placeholder-container {
  height: calc(100vh - 500px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  padding-bottom: 0;
}
</style>
